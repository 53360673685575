/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 90px 0 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: lighten($secondary, 61);
}

.section-title {
  text-align: center;

  h2 {
    font-size: 36px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    color: $secondary;
  }

  h2::before {
    content: "";
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }

  h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: $primary;
    bottom: 0;
    left: calc(50% - 20px);
  }

  p {
    margin-bottom: 0;
  }
}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients {
  padding: 12px 0;
  text-align: center;

  img {
    max-width: 45%;
    transition: all 0.4s ease-in-out;
    display: inline-block;
    padding: 15px 0;
    filter: grayscale(100);
    &:hover {
      filter: none;
      transform: scale(1.1);
    }

    @media (max-width: 768px) {
      max-width: 40%;
    }
  }
}

/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.about {
  .content {
    h3 {
      font-weight: 600;
      font-size: 26px;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        padding-left: 28px;
        position: relative;
      }

      li + li {
        margin-top: 10px;
      }

      i {
        position: absolute;
        left: 0;
        top: 2px;
        font-size: 20px;
        color: $primary;
        line-height: 1;
      }
    }

    .btn-learn-more {
      font-family: $font-secondary;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 1px;
      display: inline-block;
      padding: 12px 32px;
      border-radius: 4px;
      transition: 0.3s;
      line-height: 1;
      color: $white;
      animation-delay: 0.8s;
      margin-top: 6px;
      border: 2px solid $primary;
      background-color: $primary;
      text-decoration: none;
      &:hover {
        background: darken($primary, 10);
        border: 2px solid darken($primary, 10);
      }
    }
  }
}

/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us {
  .content {
    padding: 60px 100px 0 100px;
    h3 {
      font-weight: 400;
      font-size: 34px;
      color: $secondary;
    }

    h4 {
      font-size: 20px;
      font-weight: 700;
      margin-top: 5px;
    }

    p {
      font-size: 15px;
      color: lighten($default, 25);
    }
  }

  .img {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .accordion-list {
    padding: 0 100px 60px 100px;
    ul {
      padding: 0;
      list-style: none;
    }

    li + li {
      margin-top: 15px;
    }

    li {
      padding: 20px;
      background: #fff;
      border-radius: 4px;
    }

    a {
      display: block;
      position: relative;
      font-family: $font-secondary;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      padding-right: 30px;
      outline: none;
      cursor: pointer;
    }

    span {
      color: $primary;
      font-weight: 600;
      font-size: 18px;
      padding-right: 10px;
    }

    i {
      font-size: 24px;
      position: absolute;
      right: 0;
      top: 0;
    }

    p {
      margin-bottom: 0;
      padding: 10px 0 0 0;
    }

    .icon-show {
      display: none;
    }

    a.collapsed {
      color: $black;
      &:hover {
        color: $primary;
      }

      .icon-show {
        display: inline-block;
      }

      .icon-close {
        display: none;
      }
    }
  }

  @media (max-width: 1024px) {
    .content,
    .accordion-list {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media (max-width: 992px) {
    .img {
      min-height: 400px;
    }
    .content {
      padding-top: 30px;
    }
    .accordion-list {
      padding-bottom: 30px;
    }
  }
  @media (max-width: 575px) {
    .img {
      min-height: 200px;
    }
  }
}

/*--------------------------------------------------------------
# Skills
--------------------------------------------------------------*/
.skills {
  .content {
    h3 {
      font-weight: 700;
      font-size: 32px;
      color: $secondary;
      font-family: $font-secondary;
    }

    ul {
      list-style: none;
      padding: 0;
      li {
        padding-bottom: 10px;
      }
      i {
        font-size: 20px;
        padding-right: 4px;
        color: $primary;
      }
    }
    p:last-child {
      margin-bottom: 0;
    }
  }

  .progress {
    height: 60px;
    display: block;
    background: none;
    border-radius: 0;
  }

  .progress .skill {
    padding: 0;
    margin: 0 0 6px 0;
    text-transform: uppercase;
    display: block;
    font-weight: 600;
    font-family: $font-secondary;
    color: $secondary;
  }

  .progress .skill .val {
    float: right;
    font-style: normal;
  }

  .progress-bar-wrap {
    background: lighten($secondary, 58);
    height: 10px;
  }

  .progress-bar {
    width: 1px;
    height: 10px;
    transition: 0.9s;
    background-color: lighten($secondary, 10);
  }
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services {
  .icon-box {
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
    padding: 40px 30px;
    margin: 20px 10px;
    transition: all ease-in-out 0.4s;
    background: $white;
    text-align: center;

    .icon {
      margin-bottom: 10px;

      i {
        color: $primary;
        font-size: 36px;
        transition: 0.3s;
      }
    }

    h4 {
      font-weight: 500;
      margin-bottom: 15px;
      font-size: 24px;
      a {
        color: $secondary;
        text-decoration: none;
        transition: ease-in-out 0.3s;
      }
    }

    p {
      line-height: 24px;
      font-size: 14px;
      margin-bottom: 0;
    }

    &:hover {
      transform: translateY(-10px);
      h4 a {
        color: $primary;
        filter: brightness(75%);
      }
    }
  }
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio {
  #portfolio-flters {
    list-style: none;
    margin-bottom: 20px;

    li {
      cursor: pointer;
      display: inline-block;
      margin: 10px 5px;
      font-size: 15px;
      font-weight: 500;
      line-height: 1;
      color: $default;
      transition: all 0.3s;
      padding: 8px 20px;
      border-radius: 50px;
      font-family: $font-secondary;

      &:hover,
      &.filter-active {
        background: $primary;
        color: $white;
      }
    }
  }

  .portfolio-item {
    margin-bottom: 30px;

    .portfolio-img {
      overflow: hidden;
      img {
        transition: all 0.6s;
      }
    }

    .portfolio-info {
      opacity: 0;
      position: absolute;
      left: 15px;
      bottom: 0;
      z-index: 3;
      right: 15px;
      transition: all 0.3s;
      background: rgba($secondary, 0.8);
      padding: 10px 15px;

      h4 {
        font-size: 18px;
        color: $white;
        font-weight: 600;
        margin-bottom: 0;
      }

      p {
        color: lighten($primary, 40);
        font-size: 14px;
        margin-bottom: 0;
      }

      .preview-link,
      .details-link {
        position: absolute;
        right: 40px;
        font-size: 24px;
        top: calc(50% - 18px);
        color: $white;
        transition: 0.3s;
        &:hover {
          color: $primary;
        }
      }

      .details-link {
        right: 10px;
      }
    }

    &:hover {
      .portfolio-img img {
        transform: scale(1.15);
      }
      .portfolio-info {
        opacity: 1;
      }
    }
  }
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;

  .portfolio-details-slider {
    img {
      width: 100%;
    }
    .swiper-pagination {
      margin-top: 20px;
      position: relative;

      .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
        background-color: #fff;
        opacity: 1;
        border: 1px solid $primary;
      }

      .swiper-pagination-bullet-active {
        background-color: $primary;
      }
    }
  }

  .portfolio-info {
    padding: 30px;
    box-shadow: 0 0 30px rgba($secondary, 0.08);

    h3 {
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #eee;
    }

    ul {
      list-style: none;
      padding: 0;
      font-size: 15px;

      li + li {
        margin-top: 10px;
      }
    }
  }

  .portfolio-description {
    padding-top: 30px;

    h2 {
      font-size: 26px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    p {
      padding: 0;
    }
  }
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team {
  .member {
    position: relative;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
    padding: 30px;
    border-radius: 5px;
    background: $white;
    transition: 0.5s;

    .pic {
      overflow: hidden;
      width: 180px;
      border-radius: 50%;
      img {
        transition: ease-in-out 0.3s;
      }
    }

    &:hover {
      transform: translateY(-10px);
    }

    .member-info {
      padding-left: 30px;
    }

    h4 {
      font-weight: 700;
      margin-bottom: 5px;
      font-size: 20px;
      color: $secondary;
    }

    span {
      display: block;
      font-size: 15px;
      padding-bottom: 10px;
      position: relative;
      font-weight: 500;

      &::after {
        content: "";
        position: absolute;
        display: block;
        width: 50px;
        height: 1px;
        background: lighten($secondary, 50);
        bottom: 0;
        left: 0;
      }
    }

    p {
      margin: 10px 0 0 0;
      font-size: 14px;
    }

    .social {
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      a {
        transition: ease-in-out 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        width: 32px;
        height: 32px;

        background: lighten($secondary, 60);

        i {
          color: $secondary;
          font-size: 16px;
          margin: 0 2px;
        }

        &:hover {
          background: $primary;
          i {
            color: $white;
          }
        }
      }

      a + a {
        margin-left: 8px;
      }
    }
  }
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing {
  .row {
    padding-top: 40px;
  }

  .box {
    padding: 60px 40px;
    box-shadow: 0 3px 20px -2px rgba(20, 45, 100, 0.1);
    background: #fff;
    height: 100%;
    border-top: 4px solid $white;
    border-radius: 5px;
  }

  h3 {
    font-weight: 500;
    margin-bottom: 15px;
    font-size: 20px;
    color: $secondary;
  }

  h4 {
    font-size: 48px;
    color: $secondary;
    font-weight: 400;
    font-family: $font-primary;
    margin-bottom: 25px;
    sup {
      font-size: 28px;
    }

    span {
      color: $primary;
      font-size: 18px;
      display: block;
    }
  }

  ul {
    padding: 20px 0;
    list-style: none;
    color: #999;
    text-align: left;
    line-height: 20px;

    li {
      padding: 10px 0 10px 30px;
      position: relative;
    }

    i {
      color: $green;
      font-size: 24px;
      position: absolute;
      left: 0;
      top: 6px;
    }
    .na {
      color: #ccc;
      i {
        color: #ccc;
      }
      span {
        text-decoration: line-through;
      }
    }
  }

  .buy-btn {
    display: inline-block;
    padding: 12px 35px;
    border-radius: 50px;
    color: $primary;
    font-size: 16px;
    font-weight: 500;
    font-family: $font-primary;
    transition: 0.3s;
    border: 1px solid $primary;
    &:hover {
      background: $primary;
      color: $white;
    }
  }

  .featured {
    border-top-color: $primary;
    .buy-btn {
      background: $primary;
      color: $white;
      &:hover {
        background: darken($primary, 8);
      }
    }
  }

  @media (max-width: 992px) {
    .box {
      max-width: 60%;
      margin: 0 auto 30px auto;
    }
  }

  @media (max-width: 767px) {
    .box {
      max-width: 80%;
      margin: 0 auto 30px auto;
    }
  }

  @media (max-width: 420px) {
    .box {
      max-width: 100%;
      margin: 0 auto 30px auto;
    }
  }
}

/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq {
  .faq-list {
    padding: 0 100px;
    ul {
      padding: 0;
      list-style: none;
    }

    li + li {
      margin-top: 15px;
    }

    li {
      padding: 20px;
      background: #fff;
      border-radius: 4px;
      position: relative;
    }

    a {
      display: block;
      position: relative;
      font-family: $font-secondary;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      padding: 0 30px;
      outline: none;
      cursor: pointer;
    }

    .icon-help {
      font-size: 24px;
      position: absolute;
      right: 0;
      left: 20px;
      color: $primary;
    }

    .icon-show,
    .icon-close {
      font-size: 24px;
      position: absolute;
      right: 0;
      top: 0;
    }

    p {
      margin-bottom: 0;
      padding: 10px 0 0 0;
    }

    .icon-show {
      display: none;
    }

    a.collapsed {
      color: $secondary;
      transition: 0.3s;
      &:hover {
        color: $primary;
      }

      .icon-show {
        display: inline-block;
      }

      .icon-close {
        display: none;
      }
    }
  }

  @media (max-width: 1200px) {
    .faq-list {
      padding: 0;
    }
  }
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact {
  .info {
    border-top: 3px solid $primary;
    border-bottom: 3px solid $primary;
    padding: 30px;
    background: #fff;
    width: 100%;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);

    i {
      font-size: 20px;
      color: $primary;
      float: left;
      width: 44px;
      height: 44px;
      background: lighten($primary, 36);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      transition: all 0.3s ease-in-out;
    }

    .address,
    .email,
    .phone {
      h4 {
        padding-left: 60px;
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 5px;
        color: $secondary;
      }
      p {
        padding-left: 60px;
        margin-bottom: 5px;
        font-size: 14px;
        color: lighten($secondary, 20);
      }
      a {
        padding-left: 15px;
        margin-bottom: 20px;
        font-size: 14px;
        color: lighten($secondary, 20);
      }
      padding-bottom: 20px;
    }

    .social-links {
      padding-left: 60px;
      a {
        font-size: 18px;
        display: inline-block;
        background: #333;
        color: #fff;
        line-height: 1;
        padding: 8px 0;
        border-radius: 50%;
        text-align: center;
        width: 36px;
        height: 36px;
        transition: 0.3s;
        margin-right: 10px;
        &:hover {
          background: $primary;
          color: #fff;
        }
      }
    }

    .email:hover,
    .address:hover,
    .phone:hover {
      i {
        background: $primary;
        color: $white;
      }
    }
  }

  .email-form {
    width: 100%;
    border-top: 3px solid $primary;
    border-bottom: 3px solid $primary;
    padding: 30px;
    background: #fff;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);

    .form-group {
      padding-bottom: 8px;
    }

    .validate {
      display: none;
      color: red;
      margin: 0 0 15px 0;
      font-weight: 400;
      font-size: 13px;
    }

    .error-message {
      display: none;
      color: #fff;
      background: #ed3c0d;
      text-align: left;
      padding: 15px;
      font-weight: 600;
      & br + br {
        margin-top: 25px;
      }
    }

    .sent-message {
      display: none;
      color: #fff;
      background: #18d26e;
      text-align: center;
      padding: 15px;
      font-weight: 600;
    }

    .loading {
      display: none;
      background: #fff;
      text-align: center;
      padding: 15px;

      &:before {
        content: "";
        display: inline-block;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        margin: 0 10px -6px 0;
        border: 3px solid #18d26e;
        border-top-color: #eee;
        animation: animate-loading 1s linear infinite;
      }
    }

    .form-group {
      margin-bottom: 20px;
    }

    label {
      padding-bottom: 8px;
    }

    input,
    textarea {
      box-shadow: none;
      font-size: 14px;
      border-radius: 4px;
      &:focus {
        border-color: $primary;
      }
    }

    input {
      height: 44px;
    }

    textarea {
      padding: 10px 12px;
    }

    button[type="submit"] {
      background: $primary;
      border: 0;
      padding: 12px 34px;
      color: #fff;
      transition: 0.4s;
      border-radius: 50px;
      &:hover {
        background: darken($primary, 10);
      }
    }
  }

  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: lighten($secondary, 61);
  min-height: 40px;
  margin-top: 72px;

  @media (max-width: 992px) {
    margin-top: 68px;
  }

  h2 {
    font-size: 28px;
    font-weight: 600;
    color: $secondary;
  }

  ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0 0 10px 0;
    margin: 0;
    font-size: 14px;

    li + li {
      padding-left: 10px;
    }

    li + li::before {
      display: inline-block;
      padding-right: 10px;
      color: lighten($secondary, 10);
      content: "/";
    }
  }
}
