/*--------------------------------------------------------------
# Home Section
--------------------------------------------------------------*/
#home {
  width: 100%;
  height: 85vh;
  min-height: 500px;
  background: $secondary;

  .container {
    padding-top: 72px;
  }

  h1 {
    margin: 0 0 10px 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: $white;
  }

  h2 {
    color: rgba($white, 0.6);
    margin-bottom: 50px;
    font-size: 24px;
  }

  .btn-get-started {
    font-family: $font-primary;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 28px 11px 28px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px 0 0 0;
    color: $white;
    background: $orange;
    text-decoration: none;
    &:hover {
      background: $darkorange;
    }
  }

  .btn-watch-video {
    font-size: 16px;
    display: flex;
    align-items: center;
    transition: 0.5s;
    margin: 10px 0 0 25px;
    color: $white;
    line-height: 1;

    i {
      line-height: 0;
      color: $white;
      font-size: 32px;
      transition: 0.3s;
      margin-right: 8px;
    }

    &:hover {
      i {
        color: $primary;
      }
    }
  }

  .animated {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
  }

  @media (max-width: 991px) {
    height: auto;
    text-align: center;

    .animated {
      animation: none;
    }

    .home-img {
      text-align: center;
      img {
        width: 50%;
      }
    }
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 28px;
      line-height: 36px;
    }

    h2 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }

    .home-img img {
      width: 70%;
    }
  }

  @media (max-width: 575px) {
    .home-img img {
      width: 80%;
    }

    .btn-get-started {
      font-size: 16px;
      padding: 10px 24px 11px 24px;
    }
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}
