/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: $font-default;
  color: $default;
}

a {
  color: $primary;
  text-decoration: none;
}

a:hover {
  color: lighten($primary, 10);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-primary;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: $secondary;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid $secondary;
  border-top-color: $white;
  border-bottom-color: $white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: animate-preloader 1s linear infinite;
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: $primary;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;

  i {
    font-size: 24px;
    color: #fff;
    line-height: 0;
  }

  &:hover {
    background: lighten($primary, 8);
    color: #fff;
  }

  &.active {
    visibility: visible;
    opacity: 1;
  }
}
